@import "../variables/colors";
@import "../variables/sizes";

@__spinner_first-color: @theme-color;
@__spinner_second-color: @theme-color-darken;
@__spinner_third-color: @theme-color-lighten;
@__spinner_size: @spinner_size;

.spinner {
  display: block;
  position: relative;
  left: calc(50% - @spinner_size/2);
  top: calc(50% - @spinner_size/2);
  width: @spinner_size;
  height: @spinner_size;
  margin: -@spinner_size/2 0 0 -@spinner_size/2;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: @__spinner_second-color;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.spinner:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: @__spinner_first-color;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.spinner:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: @__spinner_third-color;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
