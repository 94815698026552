.content-embed {
  .body {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .navbar {
    visibility: hidden;
  }

  footer {
    visibility: hidden;
  }

  .side-bar {
    top: 0;
  }

  .map {
    top: 0px;
    bottom: 0px;
  }

  .bottom-panel {
    bottom: 5px;
  }


  .right-bottom-panel {
    right: 5px;
    bottom: 5px;
  }

  .right-top-panel {
    right: 5px;
    top: 5px;
  }

  .left-panel {
    left: 5px;
    top: 5px;
    bottom: 5px;
  }
}
