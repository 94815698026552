/* You can add global styles to this file, and also import other style files */
@import "styles/variables/colors";
@import "styles/variables/sizes";
@import "styles/mixins/btn-bootstrap";
@import "styles/mixins/scrollable";
@import "styles/mixins/floating-panel";
@import "styles/components/btn-gray";
@import "styles/components/buttons";
@import "styles/components/navbar";
@import "styles/components/side-panel";
@import "styles/components/spinner";
@import "styles/fonts/qanelas-soft";

@import "../Styles/integrated-layout.less";

/* Column Priorities */
@media only all {
  .p-datatable-scrollable .p-datatable-thead > tr > th.ui-p-6, .p-datatable-scrollable .p-datatable-tbody > tr > td.ui-p-6, .p-datatable-scrollable .p-datatable-tfoot > tr > td.ui-p-6,
  .p-datatable-scrollable .p-datatable-thead > tr > th.ui-p-5, .p-datatable-scrollable .p-datatable-tbody > tr > td.ui-p-5, .p-datatable-scrollable .p-datatable-tfoot > tr > td.ui-p-5,
  .p-datatable-scrollable .p-datatable-thead > tr > th.ui-p-4, .p-datatable-scrollable .p-datatable-tbody > tr > td.ui-p-4, .p-datatable-scrollable .p-datatable-tfoot > tr > td.ui-p-4,
  .p-datatable-scrollable .p-datatable-thead > tr > th.ui-p-3, .p-datatable-scrollable .p-datatable-tbody > tr > td.ui-p-3, .p-datatable-scrollable .p-datatable-tfoot > tr > td.ui-p-3,
  .p-datatable-scrollable .p-datatable-thead > tr > th.ui-p-2, .p-datatable-scrollable .p-datatable-tbody > tr > td.ui-p-2, .p-datatable-scrollable .p-datatable-tfoot > tr > td.ui-p-2,
  .p-datatable-scrollable .p-datatable-thead > tr > th.ui-p-1, .p-datatable-scrollable .p-datatable-tbody > tr > td.ui-p-1, .p-datatable-scrollable .p-datatable-tfoot > tr > td.ui-p-1 {
    display: none;
  }

    td .p-column-title {
        display: none;
        padding: .4rem;
        min-width: 30%;
        margin: -.4rem 1rem -.4rem -.4rem;
        font-weight: 700;
    }

  .vms-table {
    //height: calc(100vh - 131px);
    height: calc(100vh - 112px);
    margin: -2em;
  }

  .h-full {
    height: 100%;
  }
}

/*@media (max-width: 991px) {
    .vms-table {
        height: calc(100vh - 75px);
    }
}*/

/*@media (max-width: 991px) {
    .layout-wrapper .layout-content {
        margin: -2em;
    }
}*/

@media screen and (max-width: 40em) {
    td .p-column-title {
        display: inline-block;
    }
}
/* Show priority 1 at 320px (20em x 16px) */
@media screen and (min-width: 0em) {
  .p-datatable-scrollable .p-datatable-thead > tr > th.ui-p-1, .p-datatable-scrollable .p-datatable-tbody > tr > td.ui-p-1, .p-datatable-scrollable .p-datatable-tfoot > tr > td.ui-p-1 {
    display: flex;
  }
}
/* Show priority 2 at 480px (30em x 16px) */
@media screen and (min-width: 20em) {
  .p-datatable-scrollable .p-datatable-thead > tr > th.ui-p-2, .p-datatable-scrollable .p-datatable-tbody > tr > td.ui-p-2, .p-datatable-scrollable .p-datatable-tfoot > tr > td.ui-p-2 {
    display: flex;
  }
}
/* Show priority 3 at 640px (40em x 16px) */
@media screen and (min-width: 40em) {
  .p-datatable-scrollable .p-datatable-thead > tr > th.ui-p-3, .p-datatable-scrollable .p-datatable-tbody > tr > td.ui-p-3, .p-datatable-scrollable .p-datatable-tfoot > tr > td.ui-p-3 {
    display: flex;
  }
}
/* Show priority 4 at 800px (50em x 16px) */
@media screen and (min-width: 50em) {
  .p-datatable-scrollable .p-datatable-thead > tr > th.ui-p-4, .p-datatable-scrollable .p-datatable-tbody > tr > td.ui-p-4, .p-datatable-scrollable .p-datatable-tfoot > tr > td.ui-p-4 {
    display: flex;
  }
}
/* Show priority 5 at 960px (60em x 16px) */
@media screen and (min-width: 60em) {
  .p-datatable-scrollable .p-datatable-thead > tr > th.ui-p-5, .p-datatable-scrollable .p-datatable-tbody > tr > td.ui-p-5, .p-datatable-scrollable .p-datatable-tfoot > tr > td.ui-p-5 {
    display: flex;
  }
}
/* Show priority 6 at 1,120px (70em x 16px) */
@media screen and (min-width: 70em) {
  .p-datatable-scrollable .p-datatable-thead > tr > th.ui-p-6, .p-datatable-scrollable .p-datatable-tbody > tr > td.ui-p-6, .p-datatable-scrollable .p-datatable-tfoot > tr > td.ui-p-6 {
    display: flex;
  }
}

.p-datatable .p-datatable-tbody > tr > td {
  border-width: 0 0 1px 0;
}

.p-datatable .p-datatable-tbody > tr > td > p-skeleton {
    width:100%;
}

.layout-megamenu {
  flex-flow: wrap;
  max-width: 650px;

  .full-width {
    width: 100% !important;
  }
}

  .badge {
    padding: 0 10px;
    border-radius: 10px;
    cursor: default;
  }

  .p-text-gray {
    color: darkgray;
  }

  .p-text-bold {
    font-weight: bold;
  }

  body .ui-widget-content {
    background-color: #eeeeee;
  }

  body .ui-table .ui-table-tbody > tr > td {
    border-right-color: transparent;
  }

  body .ui-table .ui-table-tbody .inner-row > td {
    border-bottom-color: transparent;
    //border-right-color: transparent;
    //border-bottom: 1px solid #dddddd;
  }

  body .ui-table .ui-table-tbody > tr {
    background: #ffffff; //rgba(0, 0, 0, 0.05);
  }

  body .ui-table .ui-table-tbody > tr:nth-child(even) {
    background-color: #ffffff;
  }

  .ui-table .ui-table-tbody .row-group {
    height: 34px;
    background-color: #5e81ac;
    color: #ffffff;
  }

  .layout-rightpanel-active .layout-rightpanel {
    transform: translate3d(0px, 0px, 0px);
  }

  .loader-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(to right, #131313, #505050 50%, #131313 100%);
    background-repeat: no-repeat;
    //font-family: Qanelas Soft;
    font-weight: 200;

    .loader-header {
      color: @theme-color-lighten;
      font-size: 48px;
      position: absolute;
      top: calc(50% - @spinner_size);
      right: 0;
      left: 0;
      margin: 0 auto;
      text-align: center;
    }

    .loader-text {
      color: @theme-color-darken;
      font-size: 24px;
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
    }
  }

  form {

    .header-label() {
      font-weight: 600;
      margin-bottom: .25rem;
      margin-top: .25rem;
    }

    .form-group {
      //margin-top: 5px;
      margin-bottom: 5px;
    }

    .group-header {
      label {
        .header-label()
      }
    }

    .form-header {
      background-color: rgba(100, 100, 100, 0.4);
      border-width: 1px 0 1px 0;
      border-radius: 0;
      border-style: solid;
      border-color: #5d5d5d;
      color: @foreground-color;
      margin-top: 10px;
      margin-bottom: 5px;
      outline: 0;
      padding: 0 15px;

      label {
        .header-label()
      }

      .form-check {
        padding-top: 5px;
        padding-bottom: 5px;

        label {
          margin: 0;
        }
      }
    }

    .has-error {
      color: @invalid-color;
      //@invalid-color; //#e65350;
    }
  }

  .caret {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid @foreground-color;
    display: inline-block;
    margin-bottom: 2px;
  }

  a[aria-expanded="true"] {
    .caret {
      border-top: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid @foreground-color;
    }
  }

  .scrollable-filter {
    .scrollable;
    max-height: 300px;
    margin-right: -12px;
  }

  .hidden {
    //bottom: unset;
    display: none;
  }

  .leaflet-popup-content {
    margin-top: 8px;
  }

  .leaflet-popup-content-wrapper {
    background: #303030;
    color: #c3c3c3;
    border-radius: 0;
  }

  .leaflet-popup-tip {
    background: #303030;
  }
