@import '../mixins/floating-panel';

@media screen and (min-width: 767px) {

  .left-panel {
    top: 1px;
    left: 1px;
    bottom: 1px;
  }

  .right-panel {
    top: 1px;
    right: 1px;
    bottom: 1px;
  }

  .right-top-panel {
    top: 1px;
    right: 1px;
  }
}
